import React, { useState, useEffect } from "react";
import "./style.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LessonTitle from "../lesson-title";
import { getLessonByIdPractices } from "../../../../../api/lesson";
import DownloadQuiz from "../download-quiz";
const EmptyListStyled = styled.p`
  color: #ccc;
  display: block;
  justify-content: center;
  text-align: center;
  margin: 3em auto;
`;

const PracticeItem = ({ quiz, setLoading}) => {
  const history = useHistory();

  const goTo = (path) => {
    history.push(path);
  };

  return (
    <div className="container-quiz-lesson">
      { parseInt(quiz.LevelOfKnowledge) <= 2 ? 
        <a onClick={() => goTo(`/students/lesson/quiz/${quiz.Id}`)}>
            <div className="col-left--quiz-lesson">{quiz.Title}</div> 
        </a> :
        <DownloadQuiz lessonId={quiz.LessonId} quizId={quiz.Id} quizTitle={quiz.Title} setLoading={setLoading}/>
      }
    </div>
  );
};

const Practice = ({ lesson, kids, subject, lessonId, setLoading, loading }) => {
  const { t, i18n } = useTranslation(["detail-lesson"]);
  const [practices, setPractices] = useState([]);

  useEffect(() => {
    getPracticesByLesson();
  }, []);

  const getPracticesByLesson = async () => {
    setLoading(true);
    try {
      const res = await getLessonByIdPractices(lessonId);
      const data = res.data;
      setPractices(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  let className = "wrapper-iframe";
  if (kids) {
    className = "wrapper-iframe-kids";
  }

  return (
    <div className="wrapper-content">
      <div className="center-container">
        <div className={className}>
          {lesson && <LessonTitle subject={subject} lesson={lesson} />}
          <div className="wrapper-quiz-list">
            <h3>{t("selectPractice")}</h3>
            <div className="wrapper-lessons-progress">
              {practices.length > 0 &&
                practices.filter(
                  (q) => q.IsExam === "0"
                ).map((quiz, i) => <PracticeItem key={i} quiz={quiz} setLoading={setLoading} />)}
            </div>
            {practices.length === 0 && (
              <EmptyListStyled>{t("emptyPractice")}</EmptyListStyled>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Practice;
