import React from "react";
import "./style.css";
import { getMaterialOffline } from "../../../../../api/lesson";
import { useTranslation } from "react-i18next";

const DownloadQuiz = ({
  lessonId,
  quizId,
  setLoading,
  quizTitle
}) => {
  const { t } = useTranslation(["detail-lesson"]);

  const getMaterialOfflineByLesson = async (format) => {
    setLoading(true);
    try {
      const res = await getMaterialOffline(lessonId, quizId, format);
      
      const data = res.data;
      if (data) {
        window.open(data, "_blank");
      }                          
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <>
    <div className="col-left--quiz-lesson quiz-title">{quizTitle}</div> 
     <p className="quiz-lesson__download--wrapper">
        {t("subTitleDownloadLesson")}
        <div className="quiz-lesson__download--btn">
          <div style={{cursor: 'pointer'}} onClick={() => getMaterialOfflineByLesson("pdf")}>
            <i className="quiz-lesson__icon-file-download fas fa-file-pdf quiz-lesson__icon-file-download__pdf"></i>
            <span className="quiz-lesson__download-txt quiz-lesson__icon-file-download__pdf">PDF</span>
          </div>
          <div style={{cursor: 'pointer'}} onClick={() => getMaterialOfflineByLesson("doc")}>
            <i className="quiz-lesson__icon-file-download fas fa-file-word quiz-lesson__icon-file-download__word"></i>
            <span className="quiz-lesson__download-txt quiz-lesson__icon-file-download__word">Word</span>
          </div>
        </div>
      </p>
      
    </>
  );
};

export default DownloadQuiz;
